import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class CurrenciesProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/currencies', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/currencies/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/currencies', payload)
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/currencies/${payload.id}`, payload)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/currencies/${id}`)
  }
}

export default CurrenciesProvider
